/* global $ */
import "lazysizes";
$().ready(function(){
	setupSlideMenu();
	if ($("form").length){
		$("form").each(function(){
			$(this).addClass("floatlabel-active").validate();
			$(this).find(".js-float-wrap").FloatLabel();
		});
	}
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});
	setupHotspots();
	showRelatedProjects();
	// testimonials
	itemScroller();
	//brand scroller
	if ($(".banners").length > 0){
		setupBanners();
	}
});

if ($("#alerts").length){
	let scrolling = false;
	window.onscroll = function() {
		scrolling = true;
	};
	setInterval(function() {
		if (scrolling){
			scrolling = false;
			stickyMobileIcon();
		}
	}, 200);
} else {
	$("#mobile-menu").addClass("sticky");
}

export function stickyMobileIcon(){
	var mob_icon = $("#mobile-menu");
	var icon_clone = mob_icon.clone();
	mob_icon.after(icon_clone);
	icon_clone.css("position", "absolute");
	var mob_icon_top = icon_clone.offset().top; // to document
	icon_clone.remove();
	if ($(document).scrollTop() > mob_icon_top){
		mob_icon.addClass("sticky");
	} else {
		mob_icon.removeClass("sticky");
	}
}

export function checkBanners(){
	// brands scroller
	$(".t-home main .banners-clients").each(function(){
		var wrap = $(this);
		var stage = $(this).find(".banner-stage");
		var banner = $(this).find(".banner");
		if(banner.length > 0) {
			if (stage[0].scrollWidth > wrap.innerWidth()) {
				if (!stage.data("animating")){
					startScroll();
					stage.hover(function(){stage.stop();}, startScroll);
				}
			} else if (stage.data("animating")) { //disable scroll
				stage.stop();
				stage.off("mouseenter mouseleave");
				stage.scrollLeft(0);
			}
		}
		function startScroll(){
			stage.data("animating", "true");
			var bwidth = banner.first().outerWidth(true); //+margin
			var timing = bwidth - stage.scrollLeft();
			timing = timing * 6.5;
			stage.animate({scrollLeft: bwidth}, timing, "linear", reset);
		}
		function reset(){
			stage.children().first().appendTo(stage);
			stage.scrollLeft(0);
			startScroll();
		}
	});
}
export function setupBanners(){
	// do once - hide empties
	$("t-home main .banners").each(function(){
		var banner = $(this).find(".banner");
		if(banner.length == 0) {
			$(this).closest("section").hide();
		}
	});
	checkBanners();
}

export function itemScroller(){
	//testimonials
	if ($(".scroller .item").length > 1){
		var $wrap = $(".scroll-wrap");

		$wrap.each(function() {
			$(this).find(".scroll-nav").show();
			var $nav = $wrap.find(".nav-item");
			var $scroller = $wrap.find(".scroller");

			if ($scroller[0].scrollWidth > $(this).innerWidth()) {
				if (!$scroller.data("animating")){
					setTimeout(slide, 5000);
				}
			} else if ($scroller.data("animating")) { //disable scroll
				$scroller.stop();
				$scroller.scrollLeft(0);
				$scroller.data("animating", false);
			}

			function slide(){
				$scroller.data("animating", true);
				var $distance = $scroller.find(".item.active").outerWidth();
				var $after = $scroller.find(".item.active").next();

				$scroller.animate({scrollLeft: $distance}, 500,"linear", reset);
				// set active
				$scroller.find(".item.active").removeClass("active");
				$after.addClass("active");
				var id = $after.attr("id");
				$nav.removeClass("active");
				$nav.filter("[data-id="+id+"]").addClass("active");
			}
			function reset(){
				$scroller.children().first().appendTo($scroller);
				$scroller.scrollLeft(0);
				setTimeout(slide, 5000);
			}
		});
	}
}

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
}

export function setupHotspots() {
	var hotspot = $(".show-hotspots");
	if (hotspot){
		hotspot.click(function() {
			// clear
			$(".show-hotspots").removeClass("active");
			$(".aus-map a.project.hotspot").removeClass("active");
			// set
			$(this).addClass("active");
			var newClass = $(this).data("class");
			$(".aus-map a.project.hotspot."+ newClass).addClass("active");
		});
	}
}

export function showRelatedProjects() {
	var show = $(".show-projects");
	show.click(function() {
		$(this).toggleClass("active");
		$(this).next(".related-projects.height").toggleClass("expanded");
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});